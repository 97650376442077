import React from 'react'
import Site from 'components/site'
import Page from 'components/page'
import PageHeader from 'components/page/page-header'

const NotFound = () => {
  return (
    <Site>
      <Page>
        <PageHeader
          title="Page not found"
          subtitleAfter="Sorry, but it seems this page does not exist"
        />
      </Page>
    </Site>
  )
}

export default NotFound
